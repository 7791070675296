import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

const contactPage = ({ data, location }) => {
    return (
        <Layout location={location}>
        <SEO title="Contact Chris Reynolds" />
        <Nav />
        <div>
            <h1>Contact</h1>  
            <p>Hi, I'm Chris <span role="img" aria-label="Wave">👋🏼</span> </p> 
                
            <p>You can contact me by:</p>
            <ul>
            <li>Email: chrisreynoldsuk[AT]gmail[dot]com</li>

            <li><a href="https://twitter.com/chrisreynoldsuk" target="_blank" rel="noreferrer">@ChrisReynoldsUK</a> on Twitter</li>
            <li><a href="https://www.linkedin.com/in/chrisjreynolds/" target="_blank" rel="noreferrer">ChrisJReynolds</a> on LinkedIn</li>
            <li><a href="https://github.com/ChrisCB/" target="_blank" rel="noreferrer">ChrisCB</a> on GitHub</li>

  
            </ul>
        </div>
      </Layout>
    )
}


export default contactPage